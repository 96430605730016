// UserInfoForm.js
import React, { useState, useCallback, useEffect } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import Particles from "react-tsparticles";

import "./UserInfoForm.css";
import { loadFull } from "tsparticles";

const UserInfoForm = ({
  userInfo,
  setUserInfo,
  handleSendOtp,
  handleVerifyOtp,
  otpSent,
  otpVerified,
  otp,
  setOtp,
  errorMessage,
}) => {
  const [showConfetti, setShowConfetti] = useState(false);
  const [otpTimer, setOtpTimer] = useState(300); // 5 minutes in seconds

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);
  useEffect(() => {
    let interval;
    if (otpSent && !otpVerified && otpTimer > 0) {
      interval = setInterval(() => {
        setOtpTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [otpSent, otpVerified, otpTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!otpSent) {
      await handleSendOtp();
    } else if (!otpVerified) {
      const success = await handleVerifyOtp();
      if (success) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
      }
    }
  };

  return (
    <Box className="user-info-form-container">
      {showConfetti && (
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: true,
              zIndex: 1,
            },
            particles: {
              number: {
                value: 0,
              },
              color: {
                value: ["#00FFFC", "#FC00FF", "#fffc00"],
              },
              shape: {
                type: ["circle", "square"],
                options: {},
              },
              opacity: {
                value: 1,
                animation: {
                  enable: true,
                  minimumValue: 0,
                  speed: 2,
                  startValue: "max",
                  destroy: "min",
                },
              },
              size: {
                value: 4,
                random: {
                  enable: true,
                  minimumValue: 2,
                },
              },
              links: {
                enable: false,
              },
              life: {
                duration: {
                  sync: true,
                  value: 5,
                },
                count: 1,
              },
              move: {
                enable: true,
                gravity: {
                  enable: true,
                  acceleration: 10,
                },
                speed: {
                  min: 10,
                  max: 20,
                },
                decay: 0.1,
                direction: "none",
                straight: false,
                outModes: {
                  default: "destroy",
                  top: "none",
                },
              },
              rotate: {
                value: {
                  min: 0,
                  max: 360,
                },
                direction: "random",
                animation: {
                  enable: true,
                  speed: 60,
                },
              },
              tilt: {
                direction: "random",
                enable: true,
                value: {
                  min: 0,
                  max: 360,
                },
                animation: {
                  enable: true,
                  speed: 60,
                },
              },
              roll: {
                darken: {
                  enable: true,
                  value: 25,
                },
                enable: true,
                speed: {
                  min: 15,
                  max: 25,
                },
              },
              wobble: {
                distance: 30,
                enable: true,
                speed: {
                  min: -15,
                  max: 15,
                },
              },
            },
            emitters: {
              life: {
                count: 0,
                duration: 0.1,
                delay: 0.4,
              },
              rate: {
                delay: 0.1,
                quantity: 150,
              },
              size: {
                width: 0,
                height: 0,
              },
            },
          }}
        />
      )}
      <form className="user-info-form" onSubmit={handleSubmit}>
        <Typography variant="h5">Just a moment...</Typography>
        <Typography variant="subtitle1">
          We'll save your results so you can easily access them again.
        </Typography>
        <TextField
          label="Name"
          variant="outlined"
          value={userInfo.name}
          onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
        />
        <TextField
          label="Email"
          variant="outlined"
          value={userInfo.email}
          onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
          onBlur={(e) =>
            setUserInfo({ ...userInfo, email: e.target.value.trim() })
          }
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
        />
        {otpSent && !otpVerified && (
          <>
            <TextField
              label="OTP"
              variant="outlined"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <Typography variant="body2" color="textSecondary">
              OTP valid for: {formatTime(otpTimer)}
            </Typography>
          </>
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={otpSent && otpTimer === 0}
        >
          {otpSent ? "Verify Email OTP" : "Verify & View Results"}
        </Button>
      </form>
    </Box>
  );
};

export default UserInfoForm;
