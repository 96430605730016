import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import "./UpgradeCard.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UpgradeCard = ({ userId, onUpgradeSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("payment");
    const sessionId = queryParams.get("session_id");

    if (paymentStatus === "success" && sessionId) {
      verifyPayment(sessionId);
    }
  }, [location]);

  const verifyPayment = async (sessionId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/verify-payment/${sessionId}`
      );
      if (response.data.success && response.data.hasUpgraded) {
        onUpgradeSuccess();
        navigate(`/report/${userId}`, { replace: true });
      }
    } catch (error) {
      console.error("Error verifying payment:", error);
    }
  };

  const handleUpgrade = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        new URLSearchParams({
          amount: 1000,
          productName: "Career Report Upgrade",
          userId,
          allow_promotion_codes: true,
        })
      );
      const { id: sessionId } = response.data;

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        throw new Error(error.message);
      } else {
        window.location.href = `/report/${userId}?payment=success`;
        onUpgradeSuccess(); // Notify parent component
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="upgrade-card">
      <Grid container>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            src="/images/Upgrade Card/CAROL 2C.jpeg"
            alt="Career Path"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h4" component="h1" align="left" gutterBottom>
              I hope you found the results useful
            </Typography>
            <Typography variant="body1" component="p" align="left" gutterBottom>
              I've reviewed your information and identified 11 additional areas
              you may need to explore. You can access these insights and receive
              even more practical advice with a Premium Subscription.
            </Typography>
            <Typography variant="body1" component="p" align="left" gutterBottom>
              {/* This service includes the 'Chat with CoachCarol™' feature,
              allowing you to discuss your personalized results or any career
              challenges you're currently facing with me.  */}
              Don't miss out—enjoy these benefits today!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpgrade}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Unlock Full Report"}
            </Button>
          </CardContent>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Card>
  );
};

export default UpgradeCard;
