import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  //   const isAuthenticated = localStorage.getItem("token"); // Assuming you store the auth token in localStorage
  const hasSubmittedAnswers = localStorage.getItem("answersSubmitted"); // You'll need to set this flag when answers are submitted

  if (!hasSubmittedAnswers) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
