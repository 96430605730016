import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ResumeTemplates from "./dashboard/ResumeTemplates";
import CareerCheatSheets from "./dashboard/CheatSheets";
import MidCareerSwitchingDashboard from "./dashboard/MidCareerSwitching";
import LandDreamJobDashboard from "./dashboard/LandYourDreamJob";
import SpecialCoaching from "./dashboard/SpecialCoaching";
import Report from "./Report";
import { useParams } from "react-router-dom";
import SummaryPage from "./SummaryPage";

const CareerDashboard = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [hasUpgraded, setHasUpgraded] = useState(false);

  useEffect(() => {
    const checkUpgradeStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade-status`
        );
        const data = await response.json();
        setHasUpgraded(data.hasUpgraded);
      } catch (error) {
        console.error("Error checking upgrade status:", error);
      }
    };

    checkUpgradeStatus();
  }, [userId]);

  const cardData = [
    {
      title: "My Career Report",
      description:
        "Your personalized career report to help you understand your career goals and aspirations",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/report/${userId}`,
      buttonText: "View Report",
      component: Report,
      requiresUpgrade: false,
    },
    {
      title: "Your Career Scorecard",
      description: "Get a one page summary of your career report",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/report/${userId}?showSummary=true`,
      buttonText: "View Career Scorecard",
      component: SummaryPage,
      requiresUpgrade: true,
    },

    {
      title: "Career Cheat Sheets",
      description: "Quick reference guides for your career development journey",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/cheat-sheets/${userId}`,
      buttonText: "View Cheat Sheets",
      component: CareerCheatSheets,
      requiresUpgrade: true,
    },
    {
      title: "Mid Career Switching",
      description: "Guide and resources for successful career transitions",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/mid-career-switching/${userId}`,
      buttonText: "Learn More",
      component: MidCareerSwitchingDashboard,
      requiresUpgrade: true,
    },
    {
      title: "Land Your Dream Job",
      description: "Expert strategies and tips to secure your ideal position",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/land-your-dream-job/${userId}`,
      buttonText: "Get Started",
      component: LandDreamJobDashboard,
      requiresUpgrade: true,
    },
    {
      title: "Special Coaching",
      description: "Get personalized career coaching at exclusive member rates",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/special-coaching/${userId}`,
      buttonText: "Learn More",
      component: SpecialCoaching,
      requiresUpgrade: true,
    },
    {
      title: "Resume Templates",
      description:
        "Professional resume templates to help you stand out in your job search",
      image: "/images/Dashboard/Designer.jpeg",
      path: `/resume-templates/${userId}`,
      buttonText: "View Templates",
      component: ResumeTemplates,
      requiresUpgrade: true,
    },
  ];

  const DashboardCard = ({
    title,
    description,
    image,
    path,
    buttonText,
    requiresUpgrade,
  }) => (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        opacity: !hasUpgraded && requiresUpgrade ? 0.7 : 1,
        background: `linear-gradient(135deg, 
          hsl(${Math.random() * 360}, 70%, 90%),
          hsl(${Math.random() * 360}, 70%, 90%),
          hsl(${Math.random() * 360}, 70%, 90%),
          hsl(${Math.random() * 360}, 70%, 90%),
          hsl(${Math.random() * 360}, 70%, 90%))`,
      }}
    >
      <CardMedia component="img" height="140" image={image} alt={title} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
      <CardActions>
        {title === "Resume Templates" || title === "Special Coaching" ? (
          <Typography variant="body2" color="primary">
            Coming Soon
          </Typography>
        ) : (
          <>
            <Tooltip
              title={
                !hasUpgraded && requiresUpgrade
                  ? "Please upgrade to access this feature"
                  : ""
              }
            >
              <span>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => navigate(path)}
                  disabled={!hasUpgraded && requiresUpgrade}
                >
                  {buttonText}
                </Button>
              </span>
            </Tooltip>
            {!hasUpgraded && requiresUpgrade && (
              <Typography variant="body2" color="error">
                Upgrade to View
              </Typography>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 3,
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Career Dashboard
      </Typography>
      <Grid container spacing={3}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <DashboardCard {...card} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CareerDashboard;
