import React, { useRef, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const VideoPlayer = ({ open, onClose, videoUrl, title }) => {
  const videoRef = useRef(null);

  const preventDownload = (e) => {
    e.preventDefault();
    return false;
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.controlsList.remove("download");
      videoRef.current.addEventListener("contextmenu", preventDownload);
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            position: "relative",
            paddingTop: "56.25%",
            WebkitTouchCallout: "none",
            WebkitUserSelect: "none",
            KhtmlUserSelect: "none",
            MozUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }}
          onContextMenu={preventDownload}
        >
          <video
            ref={videoRef}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src={videoUrl}
            controls
            controlsList="nodownload noplaybackrate fullscreen"
            disablePictureInPicture
            onContextMenu={preventDownload}
            onKeyDown={preventDownload}
            onCopy={preventDownload}
          >
            Your browser does not support the video tag.
          </video>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default VideoPlayer;
