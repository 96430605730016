import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Link,
} from "@mui/material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import QrCode2Icon from "@mui/icons-material/QrCode2";

const SpecialCoaching = () => {
  const paymentLink = "https://your-payment-gateway.com/special-coaching";
  const qrCodeImage = "/images/qr-code-coaching.png";

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={2}>
          <SupervisorAccountIcon sx={{ fontSize: 40, mr: 2 }} />
          <Typography gutterBottom variant="h5" component="div">
            Special Coaching Rates
          </Typography>
        </Box>
        <Typography variant="body2" color="text.secondary" paragraph>
          Get exclusive access to personalized career coaching at special member
          rates.
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          my={3}
        >
          <img
            src={qrCodeImage}
            alt="QR Code for Special Coaching"
            style={{ width: 200, height: 200, marginBottom: 16 }}
          />
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Scan QR code or click below
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<QrCode2Icon />}
          component={Link}
          href={paymentLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Access Special Rates
        </Button>
      </CardContent>
    </Card>
  );
};

export default SpecialCoaching;
