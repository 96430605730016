import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  CardMedia,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoPlayer from "./VideoPlayer";

const LandDreamJob = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  const videos = [
    {
      title: "So, you want to land your Dream Job",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069669/00_-_Introduction_FINAL_tk8gr8.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069669/00_-_Introduction_FINAL_tk8gr8.jpg",
    },
    {
      title: "What is a Career Strategy",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071450/1_-_Career_Strategy_FINAL_Compressed_yoqitc.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071450/1_-_Career_Strategy_FINAL_Compressed_yoqitc.jpg",
    },
    {
      title: "How to Build Your Professional Brand",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071481/2_-_Branding_FINAL_pt1lwj.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071481/2_-_Branding_FINAL_pt1lwj.jpg",
    },
    {
      title: "LinkedIn Strategies That Get You Hired",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069681/3_-_Linkedin_Strategies_FINAL_tavqjm.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069681/3_-_Linkedin_Strategies_FINAL_tavqjm.jpg",
    },
    {
      title: "Build Your Job Search Resilience",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069678/6_-_Resilience_FINAL_wpikjo.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069678/6_-_Resilience_FINAL_wpikjo.jpg",
    },
    {
      title: "How to Network Strategically",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071781/5_-_Strategic_Networking_FINAL_e10rzi.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071781/5_-_Strategic_Networking_FINAL_e10rzi.jpg",
    },
    {
      title: "Interviewing Techniques That Get You Hired",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071856/7_-_Interviewing_Basics_FINAL_pvrwbj.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071856/7_-_Interviewing_Basics_FINAL_pvrwbj.jpg",
    },
    {
      title: "Resume Tips That Get You Noticed",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069682/8_-_Resume_Basics_FINAL_a3aopz.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069682/8_-_Resume_Basics_FINAL_a3aopz.jpg",
    },
  ];

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setOpenPlayer(true);
  };

  return (
    <>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <WorkIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography gutterBottom variant="h5" component="div">
              Land Your Dream Job
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" paragraph>
            Expert guidance and strategies to help you secure your ideal
            position.
          </Typography>
          <Grid container spacing={2}>
            {videos.map((video, index) => {
              const color1 = `hsl(${Math.random() * 360}, 70%, 90%)`;
              const color2 = `hsl(${Math.random() * 360}, 70%, 90%)`;

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      background: `linear-gradient(135deg, ${color1}, ${color2})`,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="180"
                      image={video.thumbnail}
                      alt={video.title}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "120px", // Fixed height for content area
                      }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          height: "60px", // Fixed height for title
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {video.title}
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => handleVideoClick(video)}
                        fullWidth
                      >
                        Play Video
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      <VideoPlayer
        open={openPlayer}
        onClose={() => setOpenPlayer(false)}
        videoUrl={selectedVideo?.url}
        title={selectedVideo?.title}
      />
    </>
  );
};

export default LandDreamJob;
