import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./UserInfoForm.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-otp`,
        { email }
      );
      if (response.data.success) {
        setOtpSent(true);
        setErrorMessage("");
      } else {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      setErrorMessage("Error sending OTP. Please try again.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/verify-otp`,
        { email, otp }
      );
      if (response.data.success) {
        console.log(response.data.success);
        // Fetch user information
        const userResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/email/${email}`
        );
        if (userResponse.data && userResponse.data.id) {
          // Redirect to the report page with the correct userId
          //   navigate(`/report/${userResponse.data.id}`);
          localStorage.setItem("userId", userResponse.data.id);
          localStorage.setItem("answersSubmitted", "true");
          navigate(`/dashboard`);
        } else {
          setErrorMessage("User not found. Please try again.");
        }
      } else {
        setErrorMessage("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setErrorMessage("Error verifying OTP. Please try again.");
    }
  };

  return (
    <Box className="user-info-form-container">
      <form className="user-info-form" onSubmit={(e) => e.preventDefault()}>
        <Typography variant="h5">Welcome Back!</Typography>
        <Typography variant="subtitle1">
          Please enter your email to access your report.
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
          disabled={otpSent}
        />
        {otpSent && (
          <TextField
            label="OTP"
            variant="outlined"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
        )}
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={otpSent ? handleVerifyOtp : handleSendOtp}
        >
          {otpSent ? "Verify OTP" : "Send OTP"}
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;
