import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Grid,
  CardMedia,
} from "@mui/material";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import VideoPlayer from "./VideoPlayer";

const MidCareerSwitching = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);

  const videos = [
    {
      title: "Introduction to Mid-Career Switching",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203897/CareerSwitching_01_-_Introduction_ngov1r.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203897/CareerSwitching_01_-_Introduction_ngov1r.jpg",
    },
    {
      title: "What are the 5 Steps? Knowing Your Career Quadrants ",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203894/CareerSwitching_02_-_5_Steps_Quadrants_lnmi03.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203894/CareerSwitching_02_-_5_Steps_Quadrants_lnmi03.jpg",
    },
    {
      title: "What are your 4 Career Pivots?",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203895/CareerSwitching_03_-_Career_Pivots_ovusij.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203895/CareerSwitching_03_-_Career_Pivots_ovusij.jpg",
    },
    {
      title:
        "Identifying Your Career Adjacencies & Researching Your Target Markets",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203898/CareerSwitching_04_-_Career_Adjacencies_and_Research_z3vrnv.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203898/CareerSwitching_04_-_Career_Adjacencies_and_Research_z3vrnv.jpg",
    },
    {
      title: "Uncovering Opportunities via Networking",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_05_-_Networking_cvu8yb.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_05_-_Networking_cvu8yb.jpg",
    },
    {
      title: "Putting It All Together",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_06_-_Putting_it_together_xqzwgv.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203887/CareerSwitching_06_-_Putting_it_together_xqzwgv.jpg",
    },
    {
      title: "Bonus Materials & Conclusion",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203889/CareerSwitching_07_-_Bonus_rdgyeo.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724203889/CareerSwitching_07_-_Bonus_rdgyeo.jpg",
    },
  ];

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setOpenPlayer(true);
  };

  return (
    <>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <SwitchAccountIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography gutterBottom variant="h5" component="div">
              Mid-Career Switching
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" paragraph>
            Comprehensive guide to successfully navigate your career transition.
          </Typography>
          <Grid container spacing={2}>
            {videos.map((video, index) => {
              const color1 = `hsl(${Math.random() * 360}, 70%, 90%)`;
              const color2 = `hsl(${Math.random() * 360}, 70%, 90%)`;

              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card
                    sx={{
                      height: "100%", 
                      display: "flex",
                      flexDirection: "column",
                      background: `linear-gradient(135deg, ${color1}, ${color2})`,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="180"
                      image={video.thumbnail}
                      alt={video.title}
                    />
                    <CardContent 
                      sx={{ 
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "120px" // Fixed height for content area
                      }}
                    >
                      <Typography 
                        variant="h6" 
                        component="div"
                        sx={{
                          height: "60px", // Fixed height for title
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical"
                        }}
                      >
                        {video.title}
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={<PlayCircleOutlineIcon />}
                        onClick={() => handleVideoClick(video)}
                        fullWidth
                      >
                        Play Video
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      <VideoPlayer
        open={openPlayer}
        onClose={() => setOpenPlayer(false)}
        videoUrl={selectedVideo?.url}
        title={selectedVideo?.title}
      />
    </>
  );
};

export default MidCareerSwitching;
