import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MenuBookIcon from "@mui/icons-material/MenuBook";

const CheatSheets = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState(null);

  const sheets = [
    // {
    //   name: "Your Career Preferences",
    //   path: "/cheatsheets/career-preferences.pdf",
    //   description: "Understanding your career preferences and priorities",
    // },
    // {
    //   name: "Your Career Considerations",
    //   path: "/cheatsheets/career-considerations.pdf",
    //   description: "Key factors to consider in career planning",
    // },
    // {
    //   name: "Your Professional Branding Guide",
    //   path: "/cheatsheets/professional-branding-guide.pdf",
    //   description: "Build and maintain your professional brand",
    // },
    {
      name: "Your Resume Toolkit",
      path: "/landyourdreamjob/1-My-Resume-Toolkit.pdf",
      description: "Essential tools and templates for resume building",
    },
    {
      name: "Your LinkedIn and Branding Toolkit",
      path: "/landyourdreamjob/2-Your-LinkedIn-Branding-Cheatsheet.pdf",
      description: "Optimize your LinkedIn profile and personal brand",
    },
    {
      name: "Your Target Market Builder",
      path: "/landyourdreamjob/3-Your-Target-Market-Builder.pdf",
      description: "Identify and analyze your ideal job market",
    },
    {
      name: "Your Network Mapping Tool",
      path: "/landyourdreamjob/4-Your-Network-Mapping-Tool.pdf",
      description: "Map and leverage your professional network",
    },
    {
      name: "Your Job Market Research Pocket Guide",
      path: "/landyourdreamjob/5-Your-Market-Research-Pocket-Guide-1.pdf",
      description: "Research techniques for job market analysis",
    },
    {
      name: "Your Interview Preparation Guide",
      path: "/landyourdreamjob/6-Your-Interview-Preparation-Guide-1.pdf",
      description: "Comprehensive interview preparation strategies",
    },
    {
      name: "Your Job Opportunities Tracker",
      path: "/landyourdreamjob/7-Your-Job-Opportunity-Tracker-1.pdf",
      description: "Track and manage your job applications",
    },
    {
      name: "Your Salary Calculation Cheat-sheet",
      path: "/landyourdreamjob/8-Your-Salary-Calculator-1.pdf",
      description: "Calculate and compare compensation packages",
    },
    {
      name: "Your Offer & Acceptance Checklist",
      path: "/landyourdreamjob/9-Your-Offer-Acceptance-Checklist.pdf",
      description: "Evaluate and respond to job offers",
    },
  ];

  const handleDownload = (sheet) => {
    setSelectedSheet(sheet);
    setOpenDialog(true);
  };

  const confirmDownload = () => {
    const link = document.createElement("a");
    link.href = selectedSheet.path;
    link.download = selectedSheet.name + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpenDialog(false);
  };

  return (
    <>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <MenuBookIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography gutterBottom variant="h5" component="div">
              Cheat Sheets
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" paragraph>
            Quick reference guides to help you navigate your career journey.
          </Typography>
          <Grid container spacing={2}>
            {sheets.map((sheet, index) => {
              // Generate random gradient colors
              const color1 = `hsl(${Math.random() * 360}, 70%, 90%)`;
              const color2 = `hsl(${Math.random() * 360}, 70%, 90%)`;

              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      background: `linear-gradient(135deg, ${color1}, ${color2})`,
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography gutterBottom variant="h6" component="div">
                        {sheet.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {sheet.description}
                      </Typography>
                      <Button
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        onClick={() => handleDownload(sheet)}
                        fullWidth
                      >
                        Download
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Download Cheat Sheet</DialogTitle>
        <DialogContent>
          <Typography paragraph>{selectedSheet?.name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {selectedSheet?.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDownload} variant="contained">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CheatSheets;
