import { FastAverageColor } from "fast-average-color";
import React from "react";
import DOMPurify from "dompurify";

const boldText = (text, targetStrings) => {
  if (typeof text !== "string") {
    console.warn("Expected a string for text, but received:", text);
    return text; // Return the original value if it's not a string
  }

  let formattedText = text;
  targetStrings.forEach((target) => {
    const regex = new RegExp(`(${target})`, "gi");
    formattedText = formattedText.replace(
      regex,
      "<br/><strong>$1</strong><br/>"
    );
  });
  return formattedText;
};

// List of target strings to make bold
const targetStrings = [
  "You Wish To Accelerate",
  "You Wish To Hover",
  "You Wish To Downshift",
  "You Are A Crossroader",
  "You Are An Urgent Role-Seeker",
  "You Are An Accelerator",
  "You Wish To Remain In Place",
  "You Wish To Switch Industry",
  "You Wish To Switch Roles",
  "You Wish To Try Something Very Different",
  "Have a great career ahead!",

  // Add more strings as needed
];

export const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    if (!src) {
      console.warn("Attempted to preload image with undefined or null src");
      resolve(); // Resolve without error to prevent breaking the flow
      return;
    }
    const img = new Image();
    img.onload = () => {
      console.log(`Successfully preloaded image: ${src}`);
      resolve(img);
    };
    img.onerror = (error) => {
      console.warn(`Failed to preload image: ${src}`, error);
      resolve(); // Resolve without error to prevent breaking the flow
    };
    img.src = src;
  });
};

export const preloadNextImage = (currentCard, cardData) => {
  if (currentCard < cardData.length - 1) {
    const nextCard = cardData[currentCard + 1];
    if (nextCard && nextCard.media && nextCard.media.src) {
      return preloadImage(nextCard.media.src);
    } else {
      console.warn("Next card or its media source is undefined");
    }
  }
  return Promise.resolve(); // Return a resolved promise if there's no next image
};

export const getCareerPivots = (report) => {
  const score = report.careerPivots;
  if (score === 1) {
    return {
      text: "You Wish To Remain In Place ​You’ve decided that for your next career move, you intend to stay put, remain in the same industry and the same role. This could involve staying in your current company, or joining a competitor in the same role. This is the safest and least risky move to make.",
      media: {
        src: "/images/Career Direction/Career Direction Actual.png",
        type: "image",
      },
    };
  } else if (score === 2) {
    return {
      text: "You Wish To Switch Industry You've decided to stay in the same role you're in now, but pivot to another industry. You now need to focus on building new industry knowledge and adapting your skills to the new industry. Next Steps: What are the specific industries you want to target?Is there a demand for your expertise? How do you learn more about this?",
      media: {
        src: "/images/Career Direction/Career Direction Actual.png",
        type: "image",
      },
    };
  } else if (score === 3) {
    return {
      text: "You Wish To Switch Roles You've decided to stay in your current industry but change roles. Leveraging your depth of experiences and industry knowledge, your learning curve would be considerably shortened and you can transition quickly into the new role. Next Steps:  What specific roles would be interesting for you? Do you have the relevant skills that would make you attractive to the prospective Hiring Manager?",
      media: {
        src: "/images/Career Direction/Career Direction Actual.png",
        type: "image",
      },
    };
  } else if (score === 4) {
    return {
      text: "You Wish To Try Something Very Different You've decided to pivot into an entirely new area that is different from your current role or industry. This is often seen to be the riskiest move because you have to learn everything from scratch and hence, need to embark on proper planning long before you make this shift.  Next Steps: What exactly do you want to do? Have you incubated the idea and spoken to experts about it? Are you ready to make the move now or should you wait till later?",
      media: {
        src: "/images/Career Direction/Career Direction Actual.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getSkillsClarityText = (report) => {
  const score = report.skillsClarity;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your Skills Agility score is worryingly low. You're unable to describe what you're good at, what skills your market is looking for, and sometimes, you wonder if you're as skilled as your peers. Worse still, you may not have been upskilling regularly. This could lead to skills obsolescence. Next Steps: Find out what skills the market needs, and start planning on acquiring them.Speak to your bosses or experts in the field to get their points of view.",

      media: {
        src: "/images/Skills Clarity/Skills 2.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your Skills Agility score is average, but being average in this competitive environment is not good enough. You will need to constantly acquire new and relevant skills, get better at the skills you already have, find out what future skills the market will need in the coming years, and master them! Next Steps: Make a list of skills you want to acquire in the next year and find out how you can acquire/master them, either by attending courses or seeking mentorship by an expert.",
      media: {
        src: "/images/Skills Clarity/Skills 2.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your Skills Agility score is High - Congratulations! But watch out, your peers are catching up to you, so you have to keep upskilling. Constantly acquire new and relevant skills, get better at the skills you already have, find out what future skills the market will need in the coming years, and master them! Next Steps: Seek to gain mastery of the skills you already have, and make a list of skills you want to acquire in the next year. Attend courses or seek mentorship by an expert.",
      media: {
        src: "/images/Skills Clarity/Skills 2.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerStrategyScore = (report) => {
  const score = report.careerStrategyScore;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your score was found to be below industry average and this could hinder your career planning and development in the long run. Next Steps: Will your role or industry still be in demand 5 years from today? Is there Career Longevity in your job? Start thinking about where you want to be in the next 5 years and make plans to achieve it.",
      media: {
        src: "/images/Career Strategy/New Project.png",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your score was found to be at industry average. Learn how to develop your Career Strategy further to ensure better career longevity and sustainability. Next Steps: Will your role or industry still be in demand 5 years from today? Is there Career Longevity in your job? Start thinking about where you want to be in the next 5 years and make plans to achieve it.",
      media: {
        src: "/images/Career Strategy/New Project.png",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your score was found to be above industry average! Continue developing your Career Strategy further to ensure career satisfaction and longevity. Next Steps: Keep refining your Career Strategy to ensure that you continue to stay employable and in demand, no matter how volatile the market might be in the coming years.",
      media: {
        src: "/images/Career Strategy/New Project.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerAgilityScore = (report) => {
  const score = report.careerAgilityScore;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your score was found to be below industry average and this could hinder your career progression or work relevance. Next Steps: Start by assessing your Skills Inventory - are they up to date?Network actively within your industry and find a mentor who can guide you towards becoming more Career Agile.",
      media: {
        src: "/images/Career Agility/Career Agility.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your score was found to be at industry average. Find ways to enhance your Career Agility to improve your career progression! Next Steps: You could start by assessing your Skills Inventory - are they up to date? Network actively within your industry and find a mentor who can guide you towards becoming even more Career Agile.",
      media: {
        src: "/images/Career Agility/Career Agility.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your score was found to be above industry average and this will help you drive your career progression. Next Steps: Have you assessed your Skills Inventory lately? Are your capabilities up to date? Network actively within your industry and find a mentor who can guide you towards becoming more Career Agile.",
      media: {
        src: "/images/Career Agility/Career Agility.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getOverallJobHappinessScore = (report) => {
  const score = report.overallJobHappinessScore;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results indicate that you may not be  as happy in your current role as you would like it to be. Next Steps:  Do you know what your key dissatisfiers are - and how you can make things better? Speak to a Career Mentor who could give you a different perspective on things.",
      media: {
        src: "/images/Overall Job Happiness/Stressed.png",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results indicate that you feel neutral towards your current role. Whilst this is good, you might want to strive towards making it even better.  Next Steps:  Do you know what your key dissatisfiers are - and how you can make things better? Speak to a Career Mentor who could give you a different perspective on things.",
      media: {
        src: "/images/Overall Job Happiness/Neutral.png",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results indicate that you seem quite satisfied in your current role. Congratulations! Next Steps:  How can you make things even better for yourself? Have you figured out what you want more of, and how you can get it from your current job? Speak to a Career Mentor who could give you some good advice on this!",
      media: {
        src: "/images/Overall Job Happiness/Happy.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getOverallCareerWellnessScore = (report) => {
  const score = report.overallCareerWellnessScore;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results indicate that certain areas need to be further looked into. What key areas of your career that are  making you unhappy? When did you start feeling this way? How can you improve the situation? Start developing your Career Strategy today to move out of this zone!",
      media: {
        src: "/images/Career Wellness/Unhealthy.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results indicate that your career health is in the neutral zone. Ask yourself, why aren't you happier in your career? What areas should you look deeper into, and what changes can you make to improve your wellness score?",
      media: {
        src: "/images/Career Wellness/Neutral.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results indicate that your career is in the healthy zone. That's great! Start thinking about how you can sustain this in terms of Career Longevity and Job Happiness.",
      media: {
        src: "/images/Career Wellness/Healthy.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerStageScore = (report) => {
  const score = report.careerStage;
  if (score === 0) {
    return {
      text: "You Are A Crossroader You are at a point in your career where you are wondering 'What's Next?'. You may not know where to go or what to do, but you know that you need a change soon and may be feeling confused or frustrated. Next Steps: Reflect deeply and prioritize what you really want and develop a Career Strategy to guide your next steps and start researching what your ideal role looks like.Go online or start networking to learn more about it and find out how to get there. ",
      media: {
        src: "/images/Career Stage/Crossroader.png",
        type: "image",
      },
    };
  } else if (score === 1) {
    return {
      text: "You Are An Urgent Role-Seeker You are searching for the next role urgently and would like to land a job soon. You might feel frustrated that the process isn't going as well as you thought, and would like to be more effective in your jobsearch. Next Steps: Be more focused in your search! Identify and target specific jobs internally and externally. Start networking with these individuals and get your target audience to know you. Be proactive, not reactive!",
      media: {
        src: "/images/Career Stage/JobSeeker.png",
        type: "image",
      },
    };
  } else if (score === 2) {
    return {
      text: "You Are An Accelerator Your immediate desire is to progress your career to the next step in terms of skills, responsibilities and/or salary. That's great! However, you will need to start planning your campaign on how you are going to get there, as soon as possible. Next Steps: Start scanning your environment for opportunities to expand your job scope or your domain. Understand your target market - what are the skills that are growing in demand and where is this growth coming from? Make a Plan today!",
      media: {
        src: "/images/Career Stage/Accelerator 1.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerVelocityScore = (report) => {
  const score = report.careerVelocity;
  if (score === 2) {
    return {
      text: "You Wish To Accelerate You plan to progress your career in the near future by gaining more responsibility, earning a higher income, and attaining more authority and influence. It requires time, strategy, patience, and often involves enhancing skills and expertise, as well as taking on greater responsibilities. Next Steps:  Good on you! Have you figured out what your next steps are?",
      media: {
        src: "/images/Career Velocity/Accelerate.png",
        type: "image",
      },
    };
  } else if (score === 1) {
    return {
      text: "You Wish To Hover You intend to stay put at the moment. You are not planning any bold moves right now and may consider a new role only if it is a 'lateral' one that does not entail additional responsibilities.  Next Steps:  What are your reasons for hovering? How long do you intend to hover, and what are you planning to do once your hovering is done?",
      media: {
        src: "/images/Career Velocity/Hover.png",
        type: "image",
      },
    };
  } else if (score === 0) {
    return {
      text: "You Wish To Downshift You plan to take things a bit easier from here onwards to slow down your career. This move involves a deliberate reduction of the scale and scope of your work, so you can focus on other priorities in your life.  Next Steps:  Have you decided what you are going to do during this quieter period? Are you planning to focus on yourself or your loved ones? What do you intend to do once your downshift is done?",
      media: {
        src: "/images/Career Velocity/Downshift.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerAvatar = (report) => {
  const avatar = report.careerAvatar;
  let text = "";
  let media = "";
  let title = "";

  switch (avatar) {
    case "LION":
      title = "You are a Lion";
      text =
        "You are confident in your own Career and you stand tall and proud. You are agile and strategic, knowing where the best opportunities are, and should the market turn against your favour, you actively seek out new hunting ground with better options.  Next Steps: Career Lions™ have a good understanding of the environment around them and are usually good performers. However, they need to guard against complacency and pride, especially with a fast-changing Talent Market and ever-disruptive economy. They need to learn how to consistently stay ahead of the pack.";
      media = {
        src: "/images/Career Avatars/Square - Lion.jpg",
        type: "image",
      };
      break;
    case "T REX":
      title = "You are a T-Rex";
      text =
        "Big, bold and brave, you are confident that you are the king of your own domain and are probably performing well in your job. However, will you continue to be relevant in the years ahead, especially with the ever-disruptive forces affecting your markets? Will your skills remain relevant in a future market and will you still be employable to your company 5 years down the road? Next Steps:  Start figuring out how to sharpen your skills and increase your value-addedness. Identify skill-gaps and create your own professional development plan to remain employable. And don't forget to grow your network!";
      media = {
        src: "/images/Career Avatars/Square - T REX.jpg",
        type: "image",
      };
      break;
    case "CANARY":
      title = "You are a Canary";
      text =
        "You are agile and probably great at your job, but having been in your comfort zone for many years, you might have forgotten how to fly. Some Canaries are known to choose the comfort and safety of what is known, and decline great but challenging opportunities. Next Steps: Keep an open mind with regards to opportunities and start planning your Career Strategy today! Do not be afraid to volunteer for new, exciting and sometimes scary projects that are outside your comfort zone. Be brave and learn how to fly again!";
      media = {
        src: "/images/Career Avatars/Square - Canary.jpg",
        type: "image",
      };
      break;
    case "PANDA":
      title = "You are Panda";
      text =
        "Cute and cuddly, pandas are lovable but not very adaptable. Their reluctance to adapt to different climates and preference for a very narrow diet choice restricts their mobility and prevents them from venturing further into greener pastures. Are you adaptable? Do you have a Career Strategy? Or are you too comfortable where you are? Is there a risk your career could be disrupted? Next Steps: Recognise that Career Pandas are often at higher risk when re-organisation happens. Learn how to enhance your skill sets to remain relevant in your company and plan your next career moves strategically in order to enjoy career sustainability and satisfaction.";
      media = {
        src: "/images/Career Avatars/Square - Panda.jpg",
        type: "image",
      };
      break;
    default:
      text = "Career avatar not available.";
      media = {
        src: "/images/Career Velocity/Accelerate.png",
        type: "image",
      };
      break;
  }

  return { avatar, text, media, title };
};

// new cards
export const getWorkEnvironmentSatisfactionText = (report) => {
  const score = report.workEnvironmentSatisfaction;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Oh no! Your results suggest that certain areas in your work environment are unsatisfactory and may need deeper investigation. Next Steps: Do you know what is triggering you at the moment? Is there something you can do about it or maybe even manage it better?",
      media: {
        src: "/images/Work Environment Satisfaction/doctor.png",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest that there seems to be no major areas of discontent in your work environment. Next Steps: It is good that you seem satisfied with your current work environment. How can you make it even better? Are there things that you don't enjoy doing that you can delegate, automate or outsource?",
      media: {
        src: "/images/Work Environment Satisfaction/doctor.png",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Excellent! Your results seem to suggest that you are quite happy with your current team of colleagues and bosses. Next Steps: It is very encouraging that you enjoy the people around you. What can you do to make the work environment even better? Can you deepen the relationships with your team?",
      media: {
        src: "/images/Work Environment Satisfaction/doctor.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getWorkContentSatisfactionText = (report) => {
  const score = report.workContentSatisfaction;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results indicate that there are certain areas of the work that you feel may not be fulfilling and you should investigate it deeper. Next Steps: Do you know what specific areas of your job are affecting your satisfaction at the moment? Is there something you can do about it like redesigning your role? Is there someone you can speak with to make it better?",
      media: {
        src: "/images/Work Content Satisfaction/Work Content Satisfaction.jpeg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest that there seems to be no major areas of discontent in the work that you do. Next Steps: It is good that you seem satisfied with your current work! How can you make it even better? Are there things that you don't enjoy doing that you can delegate, automate or outsource? Can you ask for more meaningful work to do?",
      media: {
        src: "/images/Work Content Satisfaction/Work Content Satisfaction.jpeg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results seem to suggest that you are quite happy with the work that you are doing at the moment. Next Steps: It is great that you seem to enjoy the work you are doing! How can you make it even better? Find out what other skills you can learn to be even more effective and impactful at your job, and keep growing in your role!",
      media: {
        src: "/images/Work Content Satisfaction/Work Content Satisfaction.jpeg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getSalarySatisfactionText = (report) => {
  const score = report.salarySatisfaction;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results suggest that you are somewhat dissatisfied with your current level of remuneration. When did you begin feeling this way? What was the trigger? Do you feel underpaid because your workload is too heavy or did you find out your salary is below the market rate? Next Steps: Do your research to see if your current salary is correctly benchmarked to the market for similar roles. If you are overworked, speak to your boss about getting more resources. If you are comfortable, share your thoughts about your current salary situation with them and ask whether (or when) there could be a salary review for you.",
      media: {
        src: "/images/Salary Satisfaction/Unhappy - No Salary.jpeg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest that you are feeling quite neutral towards your current level of remuneration. This is a good sign. Next Steps: If you are planning on increasing your salary, find out how you can bring even more value to your job or what steps are needed to enhance your capabilities and progress your career. Salary increments normally reflect the contribution you bring to the company, so keep up the good work!",
      media: {
        src: "/images/Salary Satisfaction/Neutral - Salary.jpeg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results suggest that you are satisfied with your current level of remuneration. This is a good sign indeed. Next Steps: If you are planning on increasing your salary, find out how you can bring even more value to your job or what steps are needed to enhance your capabilities and progress your career. Can you ask for a bigger portfolio or more responsibilities?  Salary increments normally reflect the contribution you bring to the company, so keep up the good work!",
      media: {
        src: "/images/Salary Satisfaction/Happy - Good Salary.jpeg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getNetworkingProficiencyText = (report) => {
  const score = report.networkingProficiency;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results indicate that you are not networking enough! To attract the right growth opportunities, you have to actively reach out and grow your connections. Next Steps: Push yourself out of your comfort zone and start going out to meet people. Make it a habit to connect to people and ask to speak with them to learn more about what they do and also make a great impression. Use LinkedIn as a tool for networking – connect to at least 20 new (relevant) contacts every week to build a bigger web of contacts who can help your Career.",
      media: {
        src: "/images/Networking Proficiency/Networking Proficiency.jpeg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest that you are starting to build your reputation in the market and beginning to attract interesting opportunities. Next Steps: Use LinkedIn as a tool for networking. Connect to at least 10 new and relevant contacts who could be old colleagues, ex-bosses or even counterparts in your industry.  Share with them what exactly you have been doing professionally and offer help if they need it. Make a habit of networking and you will see the results in no time",
      media: {
        src: "/images/Networking Proficiency/Networking Proficiency.jpeg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Great work! Your results suggest you are networking at the right level to build your reputation and attract the right opportunities. Next Steps: Connect to at least 20 new and relevant contacts every week to broaden your professional network. They could be old colleagues, ex-bosses or even old classmates or counterparts in competing companies. Nurture a relationship with them if you can, and stay in touch by reconnecting regularly.",
      media: {
        src: "/images/Networking Proficiency/Networking Proficiency.jpeg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getCareerDisruptionReadinessText = (report) => {
  const score = report.careerDisruptionReadiness;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Oh no, your results indicate you do not seem very well prepared for career disruptions. Next Steps: You should put some thought into this and start building a clearer Career Strategy.  What’s next for you in your career and what are your various options in the event of a major change in your employment situation? Make plans so you can live your life more confidently!",
      media: {
        src: "/images/Career-Disruption Readiness/Disruption Ahead.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results indicate you are somewhat prepared for career disruptions. You may have a rough idea of what your next steps are likely to be, but you’re not quite confident of adapting to any sudden change quickly. Next Steps: Start updating and developing your Career Strategy. What’s your next move if a disruption happens and are you ready for it? Make plans so you can live your life more confidently!",
      media: {
        src: "/images/Career-Disruption Readiness/Disruption Ahead.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "You have indicated that if your role was disrupted, you would be confident of securing your next job fairly quickly – good for you! Next Steps: Continue refining and developing your Career Strategy. If you see a disruption ahead, what can you do to avoid it?  Make smart back up plans so you can live your life even more confidently!",
      media: {
        src: "/images/Career-Disruption Readiness/Disruption Ahead.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getWorklifeBalanceSatisfactionText = (report) => {
  const score = report.worklifeBalanceSatisfaction;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Oh no! Your results suggest you might be struggling with some work-life balance issues. Next Steps: You might want to set boundaries between work and personal time and prioritize your tasks and goals. Is the poor balance due to overwhelming work commitments, or are you just too focused on over-delivering? Reflect on what truly matters to you and make time for those things.",
      media: {
        src: "/images/Work-life Balance Satisfaction/Worklife Balance.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest that your work-life balance is somewhat acceptable to you. Next Steps: You might want to continue to prioritize your personal time and maintain work boundaries. Make a conscious effort to take time off  to do the things that matter to you, with the people who matter to you. Do not get too carried away by your work to the detriment of those around you.",
      media: {
        src: "/images/Work-life Balance Satisfaction/Worklife Balance.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Great!! Your results suggest a somewhat healthy level of work-life balance. Next Steps: Continue to prioritize your personal time and maintain boundaries. Use your extra time and energy to pursue hobbies, interests, or focus on self-care and well-being. Consider sharing your experiences and tips with others who may be struggling with their work-life balance.",
      media: {
        src: "/images/Work-life Balance Satisfaction/Worklife Balance.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getResumePreparednessText = (report) => {
  const score = report.resumePreparedness;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Oh no, it looks like you may need to refresh your CV. Next Steps: Seek advice from a mentor or career coach to ensure your CV is well-crafted and effective.  Consider updating your skills and capabilities to make yourself more attractive to the job market.",
      media: { src: "/images/Resume Preparedness/Resume.jpg", type: "image" },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Looks like you may need to update your CV. Next Steps: Seek advice from a mentor or career coach to ensure your CV is well-crafted and effective.  Consider updating your skills and capabilities to make yourself more relevant to market demands.",
      media: { src: "/images/Resume Preparedness/Resume.jpg", type: "image" },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Great! It is good that you have an updated CV prepared. Next Steps: Contnue to seek advice from a mentor or career coach to ensure your CV is well-crafted and effective.  Consider updating your skills and capabilities to make yourself more relevant to market demands.",
      media: { src: "/images/Resume Preparedness/Resume.jpg", type: "image" },
    };
  } else {
    return "N/A";
  }
};

export const getLinkedInPreparednessText = (report) => {
  const score = report.linkedInPreparedness;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results suggest your LinkedIn Profile may be non-existent or not updated. Next Steps: It is time for you to take a serious look at your Linkedin Profile - is it up to date? Are the necessary details there?  Include as many relevant keywords as possible so you can attract the right opportunities.",
      media: {
        src: "/images/LinkedIn Preparedness/Linkedin.png",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results suggest your LinkedIn Profile may need updating. Next Steps: It is time to enhance your LinkedIn Profile! Ensure that your profile is complete, up-to-date, and highlights your key achievements and skills and showcases your recent accomplishments.",
      media: {
        src: "/images/LinkedIn Preparedness/Linkedin.png",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results suggest your LinkedIn Profile has been recently updated. Great! Next Steps: It is time to optimise your LinkedIn Profile! Is your profile complete and up-to-date? Can visitors see your key achievements, skills and  recent accomplishments? Refresh your page with relevant and impactful keywords to gain better visibility.",
      media: {
        src: "/images/LinkedIn Preparedness/Linkedin.png",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getSatisfactionWithCurrentWorkloadText = (report) => {
  const score = report.satisfactionWithCurrentWorkload;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results seems to indicate you are somewhat dissatisfied with your current workload. Next Steps: To manage an overwhelming workload, prioritize tasks using methods like the Eisenhower Matrix and delegate where possible. You might want to communicate openly with your supervisor about your capacity and seek support if needed. Don't forget to ensure self-care through regular breaks, healthy habits, and mindfulness.",
      media: {
        src: "/images/Satisfaction with Current Workload/Workload.jpg",
        type: "image",
      },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results indicate that you seem neutral towards your current workload. Next Steps: Since your workload is manageable, you might want to optimize your efficiency even further by streamlining processes and using project management tools. Set new personal and professional goals to stay motivated, and take on new challenges by volunteering for additional projects or initiatives. And don't neglect your work-life balance!",
      media: {
        src: "/images/Satisfaction with Current Workload/Workload.jpg",
        type: "image",
      },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results indicate you are satisfied with your current workload. Next Steps: Given your manageable workload, focus on enhancing your career by seeking additional responsibilities within your current role, such as volunteering for new projects or initiatives, allowing you to expand your skills. Consider investing in professional development by pursuing relevant courses or certifications to further advance your capabilities. And of course, don't neglect your work-life balance!",
      media: {
        src: "/images/Satisfaction with Current Workload/Workload.jpg",
        type: "image",
      },
    };
  } else {
    return "N/A";
  }
};

export const getJobSecurityText = (report) => {
  const score = report.jobSecurity;
  if (score >= -2 && score <= -0.5) {
    return {
      text: "Your results seem to indicate that you are feeling a certain degree of uncertainty in your current job. Has your industry been undergoing a lot of disruption or retrenchment lately? Next Steps: Strive to keep yourself relevant to your company - upgrade your skills, keep delivering on your KPIs, take on new projects. Make your organisation value you. In any event, keep an eye out for better opportunities that may present themselves to you.",
      media: { src: "/images/Job Security/Job Security.jpeg", type: "image" },
    };
  } else if (score >= -0.49 && score <= 0.49) {
    return {
      text: "Your results seem to indicate that you are feeling somewhat secure in your current job. That is a good place to be, but are there signs of disruption or retrenchments in your industry? Next Steps: Strive to keep yourself relevant to your company – upgrade your skills, keep delivering on your KPIs, take on new projects. Make your organisation value you. In any event, keep an eye out for better opportunities that could present themselves to you. If you are unsure speak to your career mentor today.",
      media: { src: "/images/Job Security/Job Security.jpeg", type: "image" },
    };
  } else if (score >= 0.5 && score <= 2) {
    return {
      text: "Your results seem to indicate that you are feeling very secure in your current job. That is a good place to be, but don't get too complacent! Are there signs of disruption or retrenchments in your industry? Next Steps: Strive to keep yourself relevant to your company – upgrade your skills, keep delivering on your KPIs and take on new projects. Make your organisation value you. In any event, keep an eye out for better opportunities that could present themselves to you. If you are unsure speak to your career mentor today.",
      media: { src: "/images/Job Security/Job Security.jpeg", type: "image" },
    };
  } else {
    return "N/A";
  }
};

export const getTopNegativeWorkSatisfiers = (report) => {
  const satisfierData = {
    Work: {
      text: "It seems the nature of your current work could be a possible source of disatisfaction for you. You might want to start by identifying which particular areas of work you dislike and why. Reflect on whether it's the tasks themselves, the industry, the work environment, or other factors contributing to your dissatisfaction. Once you have clarity on what isn't working, explore how your situation can be improved. <br /><br /> You might also want to assess whether additional education or training could help in improving your situation and seek guidance from career mentors who can provide valuable insights and support. <br /><br /> By taking a proactive approach to assess your dissatisfaction, explore alternative career paths, acquire necessary skills or education, and seek guidance, you will manage your career change with more confidence and clarity.",
      media: {
        src: "/images/Work Satisfiers/Work/Work.jpg",
        type: "image",
      },
    },
    Team: {
      text: "It seems that you might be experiencing some unhappiness with the team you're working with.  Approach the situation thoughtfully and professionally. Start by evaluating the specific reasons why this is so. Is it a personality clash, communication issues, differing work styles, or a lack of teamwork and support? <br /><br /> Once you've identified the issues, consider addressing them directly if possible. Have a conversation with your team members or manager to discuss concerns and seek resolutions.  Additionally, focus on building constructive relationships and finding common ground on work-related tasks. <br /><br /> If efforts to improve the situation prove unsuccessful, you may need to evaluate whether a transfer to a different team within the organization or other opportunities elsewhere is necessary for your professional well-being.",
      media: {
        src: "/images/Work Satisfiers/Team/Unhappy Team.jpg",
        type: "image",
      },
    },
    "Job Security": {
      text: "It seems you are feeling a bit insecure about your job stability. It can be unsettling, but there are steps you can take to manage this uncertainty. <br /><br />Are you meeting or exceeding expectations in your role and actively seeking opportunities to add value? Speak with your manager or HR about your concerns in a proactive and professional manner, expressing interest in your long-term prospects in the company.<br /><br />Simultaneously, prepare for potential changes by updating your resume, and exploring job market trends in your industry. Invest in skills training to enhance your marketability. Discuss career development options with your bosses or seek advice from mentors to explore new opportunities within or outside your current organization.<br /><br />Taking proactive steps to improve your skills and readiness can help alleviate feelings of insecurity and prepare you for whatever the future may hold.",
      media: {
        src: "/images/Work Satisfiers/Job Security/Job Security.jpg",
        type: "image",
      },
    },
    "Personal Happiness": {
      text: "How are you really feeling about your job and career at the moment? Could it be better? It is essential to take proactive steps to address these feelings and explore potential avenues for improvement. <br /><br /> Start by reflecting deeply on what aspects of your job and career are causing dissatisfaction. Is it the nature of the work itself, the organizational culture, advancement opportunities, or a lack of alignment with your values and interests? Understanding these factors will help you identify the root causes of your unhappiness. <br /><br /> Once you have clarity, strategise on how you can make improve on each issue. Speaking  to a Career Coach or Mentor could provide a different perspective on your situation, leading to a more fulfilling and rewarding career path that aligns with your personal and professional aspirations.",
      media: {
        src: "/images/Work Satisfiers/Personal Happiness/UNhappy.jpg",
        type: "image",
      },
    },
    Salary: {
      text: "Are you feeling unhappy with your salary and perhaps, a little underpaid? It's crucial to approach the situation strategically and professionally. What is making you feel this way? Or are you are feeling overworked for the pay-grade you are at and feel a bit exploited? <br /><br /> Research salary benchmarks for your position and industry to determine whether your concerns are justified and consider scheduling a meeting with your manager or HR representative to discuss your salary or work concerns. Prepare for this discussion by outlining specific examples of your contributions, accomplishments, and the value you bring to the organization. <br /><br /> Alternatively, find out how you can take on more responsibilities and increase your contribution to the company. This proactive approach not only strengthens your position when negotiating for a raise but also positions you for potential advancement opportunities in the future.",
      media: {
        src: "/images/Work Satisfiers/Salary/unhappy - no money.jpeg",
        type: "image",
      },
    },
  };

  return report.topNegativeWorkSatisfiers.map((satisfier) => {
    const data = satisfierData[satisfier.title] || {
      text: "No description available",
      media: {
        src: "/images/Skills Clarity/Skills.png",
        type: "image",
      },
    };

    return {
      title: `Potential Career Blindspot #${
        report.topNegativeWorkSatisfiers.indexOf(satisfier) + 1
      } - ${satisfier.title}`,
      text: data.text,
      media: data.media,
      titleDescription: `This indicates your satisfaction with ${satisfier.title}.`,
    };
  });
};

export const getDissatisfierText = () => {
  return {
    text: "Now, based on your answers, I seem to have uncovered some potential areas that could affect your Career Health if left unchecked. I suggest you take a deeper look at these challenges and think about what actions you need to take to enjoy a happier career.",
    media: {
      src: "/images/Dissatisfiers Card/CAROL TITLE PIC.jpeg",
      type: "image",
    },
  };
};

export const getConclusionText = () => {
  return {
    text: "Many thanks for taking our Career Health Quiz. I hope found it useful. If you have any questions regarding your results, or if you have any pressing career questions, ask me directly at the prompt below and I will do my best to answer you. Have a great career ahead!",
    media: {
      src: "/images/Conclusion Card/CAROL 2C.jpeg",
      type: "image",
    },
  };
};
export const isVideo = (media) => media.type === "video";

export const getCardData = (report) => {
  if (!report) return [];

  const cards = [
    {
      ...getCareerAvatar(report),
      titleDescription:
        "This is a virtual description of your current career profile. ",
    },
    {
      title: "Overall Career Wellness Score",
      ...getOverallCareerWellnessScore(report),
      titleDescription:
        "This measures how well your Career is coming along and whether you are in a good position or not.",
      showStars: true, // Flag to show stars
      score: report.overallCareerWellnessScore,
    },
    {
      title: "Career Stage",
      ...getCareerStageScore(report),
      titleDescription:
        "This describes the your Biggest Career Focus at the moment.",
    },
    {
      title: "Career Velocity",
      ...getCareerVelocityScore(report),
      titleDescription:
        "This describes the pace of your next few career moves. Note that there are no correct or incorrect moves.",
    },
    {
      title: "Career Direction",
      ...getCareerPivots(report),
      titleDescription:
        "This describes what your next move is most likely to be. Are you planning to stay in your industry or leave it? Are you intending to remain in your role or pivot to a new one? ",
    },
    {
      title: "Skills Clarity",
      ...getSkillsClarityText(report),
      titleDescription:
        "This measures how well-skilled you are for the role you are in, and whether you ready to take on larger roles which require more skills and capabilities. ",
      showStars: true, // Flag to show stars
      score: report.skillsClarity,
    },
    {
      title: "Career Strategy Score",
      ...getCareerStrategyScore(report),
      titleDescription:
        "This measures how well prepared you are regarding your next career moves, whether to accelerate your career, or switch roles/business units/divisions/industries.",
      showStars: true, // Flag to show stars
      score: report.careerStrategyScore,
    },
    {
      title: "Career Agility Score",
      ...getCareerAgilityScore(report),
      titleDescription:
        "This measures your ability to respond quickly to opportunities and/or threats in the employment market that could affect your job or long-term career. ",
      showStars: true, // Flag to show stars
      score: report.careerAgilityScore,
    },
    {
      title: "Overall Job Happiness Score",
      ...getOverallJobHappinessScore(report),
      titleDescription:
        "This measures your overall happiness with your current job which directly or indirectly affects the other aspects of your personal happiness.  ",
      showStars: true, // Flag to show stars
      score: report.overallJobHappinessScore,
    },
    {
      title: "Work Environment Satisfaction",
      ...getWorkEnvironmentSatisfactionText(report),
      titleDescription:
        "This measures how you feel about the people around you - your colleagues and your bosses, as well as your company.",
      showStars: true, // Flag to show stars
      score: report.workEnvironmentSatisfaction,
    },
    {
      title: "Work Content Satisfaction",
      ...getWorkContentSatisfactionText(report),
      titleDescription:
        "This measures how you feel about the work that you do, whether you find it meaningful and whether it aligns to your purpose in life.",
      showStars: true, // Flag to show stars
      score: report.workContentSatisfaction,
    },
    {
      title: "Salary Satisfaction",
      ...getSalarySatisfactionText(report),
      titleDescription:
        "This indicates how satisfied you feel towards your current remuneration. It reflects whether you feel you are fairly paid for the effort you put in. ",
      showStars: true, // Flag to show stars
      score: report.salarySatisfaction,
    },
    {
      title: "Networking Proficiency",
      ...getNetworkingProficiencyText(report),
      titleDescription:
        "This measures how well you are building your market presence via networking. In today’s context, it is very important to stay networked as many career opportunities come to you via your network. ",
      showStars: true, // Flag to show stars
      score: report.networkingProficiency,
    },
    {
      title: "Career-Disruption Readiness",
      ...getCareerDisruptionReadinessText(report),
      titleDescription:
        "This measures how ready you are for a sudden disruption in your career like a retrenchment exercise, a new boss, or a sudden change in your life situation and whether you can respond quickly.",
      showStars: true, // Flag to show stars
      score: report.careerDisruptionReadiness,
    },
    {
      title: "Work-life Balance Satisfaction",
      ...getWorklifeBalanceSatisfactionText(report),
      titleDescription:
        "This is an indication of how you are coping with your present workload and whether that is affecting your personal or family life.",
      showStars: true, // Flag to show stars
      score: report.worklifeBalanceSatisfaction,
    },
    {
      title: "Resume Preparedness",
      ...getResumePreparednessText(report),
      titleDescription:
        "This indicates how up-to-date your CV is, and also reflects on how mentally prepared you are to seize any opportunities that could come your way.",
      showStars: true, // Flag to show stars
      score: report.resumePreparedness,
    },
    {
      title: "LinkedIn Preparedness",
      ...getLinkedInPreparednessText(report),
      titleDescription:
        "Because LinkedIn is an essential online career tool for internal and external branding, you must have a well-prepared profile.",
      showStars: true, // Flag to show stars
      score: report.linkedInPreparedness,
    },
    {
      title: "Satisfaction with Current Workload",
      ...getSatisfactionWithCurrentWorkloadText(report),
      titleDescription:
        "This is an indicator of your current utilisation rate and how well you are coping with the work you are responsible for.",
      showStars: true, // Flag to show stars
      score: report.satisfactionWithCurrentWorkload,
    },
    {
      title: "Job Security",
      ...getJobSecurityText(report),
      titleDescription:
        "This measures how confident you are that your role in the company will still be around for as long as you are willing to continue working for them.",
      showStars: true, // Flag to show stars
      score: report.jobSecurity,
    },
    {
      title: "Career Dissatisfiers",
      ...getDissatisfierText(),
      showStars: false,
    },
    ...getTopNegativeWorkSatisfiers(report),
    {
      title: "Thank You!",
      ...getConclusionText(),
      showStars: false,
    },
    // {
    //   title: "Generate Summary",
    //   titleDescription: "Generate your one-page summary.",
    //   media: {
    //     src: "/path/to/generate-summary-image.png",
    //     poster: "",
    //   },
    //   text: "Click the button below to generate your one-page summary.",
    //   showStars: false,
    // },
  ];
  return cards.map((card) => ({
    ...card,
    text: DOMPurify.sanitize(boldText(card.text, targetStrings)),
  }));
};
