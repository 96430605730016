import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import DescriptionIcon from "@mui/icons-material/Description";

const ResumeTemplates = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const templates = [
    { name: "Professional Resume", path: "/templates/professional-resume.pdf" },
    { name: "Creative Resume", path: "/templates/creative-resume.pdf" },
    { name: "Executive Resume", path: "/templates/executive-resume.pdf" },
    { name: "Entry Level Resume", path: "/templates/entry-level-resume.pdf" },
    { name: "Technical Resume", path: "/templates/technical-resume.pdf" },
  ];

  const handleDownload = (template) => {
    setSelectedTemplate(template);
    setOpenDialog(true);
  };

  const confirmDownload = () => {
    const link = document.createElement("a");
    link.href = selectedTemplate.path;
    link.download = selectedTemplate.name + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpenDialog(false);
  };

  return (
    <>
      <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Box display="flex" alignItems="center" mb={2}>
            <DescriptionIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography gutterBottom variant="h5" component="div">
              Resume Templates
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary" paragraph>
            Access professional resume templates designed for various career
            levels and industries.
          </Typography>
          <List>
            {templates.map((template, index) => (
              <ListItem key={index} sx={{ px: 0 }}>
                <Button
                  startIcon={<DownloadIcon />}
                  onClick={() => handleDownload(template)}
                  fullWidth
                  variant="outlined"
                  sx={{ mb: 1 }}
                >
                  {template.name}
                </Button>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Download Template</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to download {selectedTemplate?.name}?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDownload} variant="contained">
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResumeTemplates;
